import React from 'react'
import { Helmet } from 'react-helmet'

import { Layout } from '@ui/layout'

export const Base: React.FC = ({ children }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link href="https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600&display=swap" rel="stylesheet" />
    </Helmet>

    {children}
  </Layout>
)
