import React, { useCallback, useState } from 'react'
import { cx, css } from 'linaria'
import Loader from 'react-loader-spinner'

import MacFrame from '@/images/macbook-frame.png'

const DemoOnMac = ({ src, className, children }: { src?: string; className?: string; children?: React.ReactNode }) => {
  const [loading, setLoading] = useState(true)
  const handleOnPlay = useCallback(() => {
    setLoading(false)
  }, [])
  return (
    <div className={cx('relative', className)}>
      <img src={MacFrame} className="w-full" />
      <div
        className={cx(
          'absolute inset-0 flex items-center justify-center',
          css`
            padding: 3% 10% 5% 11%;
          `
        )}
      >
        {children ? (
          children
        ) : (
          <video
            src={src}
            muted={true}
            autoPlay={true}
            loop={true}
            onCanPlay={handleOnPlay}
            className={cx(loading && 'opacity-0')}
          />
        )}
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader type="ThreeDots" color="#EFF1F2" height={50} width={50} />
        </div>
      )}
    </div>
  )
}

export default DemoOnMac
