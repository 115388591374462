import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { cx } from 'linaria'

import { productList } from '@components/contants'
import { ReactComponent as LogoSvg } from '@/assets/svg/superbit-logo.svg'

type LinkType = {
  href: string
  label: string
}

interface BreadCrumbProps {
  className?: string
  path?: string
}

type BreadcrumbItemProps = {
  href: string
  label: string
}

const breadcrumbLinkClass = cx('inline-flex items-center text-secondary-grey hover:text-white focus:text-white')

const BreadcrumbSeparator = () => <span className="inline-block mx-2 text-secondary-grey">/</span>

export const BreadCrumb = ({ className, path }: BreadCrumbProps) => {
  const breadcrumbs = useMemo(() => {
    if (path?.includes('/all-products')) {
      return [{ label: 'All Products', href: path }]
    }
    const detailItem = productList.find(item => path?.includes(item.detailPath))
    if (detailItem) {
      return [
        { label: 'All Products', href: '/all-products' },
        { label: detailItem.name, href: detailItem.detailPath },
      ]
    }
    return []
  }, [])
  const { length } = breadcrumbs
  return (
    <ul className={cx('flex flex-wrap items-center', className)}>
      <Link to="/" className={breadcrumbLinkClass}>
        <LogoSvg className="mr-2" />
        <span>Superbits</span>
      </Link>
      {breadcrumbs.map(({ href, label }, idx) => {
        return idx < length - 1 ? (
          <React.Fragment key={`breadcrumb-${idx}`}>
            <BreadcrumbSeparator />
            <Link className={breadcrumbLinkClass} key={href} to={href}>
              {label}
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment key={`breadcrumb-${idx}`}>
            <BreadcrumbSeparator />
            <span className="inline-flex items-center text-secondary-grey">{label}</span>
          </React.Fragment>
        )
      })}
    </ul>
  )
}

export default BreadCrumb
