import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import defaultImage from '@/images/social-share.png'

export interface SeoProps {
  title: string
  description: string
  canonicalPath?: string
  video?: string
  images?: string[]
  appId?: string
  appStoreUrl?: string
  appName?: string
  noAppendTitle?: boolean
}

const Seo = ({
  appName,
  description,
  appStoreUrl,
  canonicalPath,
  appId,
  title,
  video,
  images = [defaultImage],
  noAppendTitle = false,
}: SeoProps) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)
  const { title: siteTitle, siteUrl } = data.site.siteMetadata

  const canonicalUrl = canonicalPath ? siteUrl + canonicalPath : siteUrl
  const finalTitle = noAppendTitle ? title : [title, siteTitle].join(' - ')

  return (
    <Helmet>
      <title>{finalTitle}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />

      {/* APP STORE METADATA */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      {appStoreUrl && <meta property="al:ios:url" content={`${appStoreUrl}://docs`} />}
      {appId && <meta property="al:ios:app_store_id" content={appId} />}
      {appName && <meta property="al:ios:app_name" content={appName} />}
      {appId && <meta name="apple-itunes-app" content={`app-id=${appId}`} />}

      {/* TWITTER */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={description} />
      {images?.map(url => (
        <meta key={`twitter-${url}`} name="twitter:image" content={siteUrl + url} />
      ))}

      {/* OPEN GRAPH */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={finalTitle} />
      {video && <meta property="og:video" content={video} />}
      {images?.map(url => (
        <meta key={url} property="og:image" content={siteUrl + url} />
      ))}
    </Helmet>
  )
}

export default Seo
