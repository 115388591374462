import React from 'react'
import { cx, css } from 'linaria'

import { ReactComponent as SvgGithub } from './svg/github.svg'
import { ReactComponent as SvgAppStore } from './svg/app-store.svg'
import { ReactComponent as SvgYCombinator } from './svg/y-combinator.svg'

import { ProductItemType } from '@/types'

export const CheckOn = ({
  name,
  githubUrl,
  appStoreUrl,
  yCombinatorUrl,
  className,
  theme = 'light',
}: ProductItemType & { className?: string; theme?: string }) => {
  return (
    <div className={cx(className, 'flex flex-col items-center section px-4')}>
      <div
        className={cx(
          'text-center mb-6',
          css`
            font-size: 21px;
          `,
          theme === 'light' && 'text-secondary-grey'
        )}
      >{`Doesn’t ${name} really help? Check on`}</div>

      <div className="flex flex-row items-center justify-between">
        {githubUrl && (
          <a title="Follow us on Github" href={githubUrl} target="blank" rel="noopener noreferer" className="mx-2">
            <SvgGithub />
          </a>
        )}
        {appStoreUrl && (
          <a title="Download on App Store" href={appStoreUrl} target="blank" rel="noopener noreferer" className="mx-2">
            <SvgAppStore />
          </a>
        )}
        {yCombinatorUrl && (
          <a
            title="Follow us on Y Combinator"
            href={yCombinatorUrl}
            target="blank"
            rel="noopener noreferer"
            className="mx-2"
          >
            <SvgYCombinator />
          </a>
        )}
      </div>
    </div>
  )
}
