import React from 'react'
import { cx, css } from 'linaria'
import { Link } from 'gatsby'

import { ReactComponent as Symbol } from './symbol.svg'
import { ReactComponent as ProductIcon } from './productIcon.svg'
import { ReactComponent as SuperbitsTypo } from './superbitsTypo.svg'

const header = {
  maxWidth: '1160px',
  width: '100%',
  margin: '0 auto',
  '@media (min-width: 992px)': {
    width: '80%',
    borderRadius: '0 0 8px 8px',
  },
}

export const Header = ({ bgColor = 'bg-black', ...rest }) => (
  <div className="sticky top-0 z-50" {...rest}>
    <div
      className={cx(
        'bg-black text-white flex justify-between px-4 md:px-16',
        css`
          ${header};
          height: 64px;
          @media (min-width: 768px) {
            height: 80px;
          }
        `
      )}
    >
      <div
        className={cx(
          'flex items-center cursor-pointer focus:outline-none',
          css`
            div:nth-child(1) {
              display: none;
            }
            @media (min-width: 768px) {
              div:nth-child(1) {
                display: block;
              }
            }
          `
        )}
      >
        <Link className="flex" to="/">
          <div className="md:flex flex-none md:mr-1 md:w-8">
            <Symbol />
          </div>
          <div className="flex items-center">
            <SuperbitsTypo />
          </div>
        </Link>
      </div>

      <div
        className={cx(
          'flex items-center cursor-pointer focus:outline-none',
          css`
            a:nth-child(2) {
              display: none;
            }
            @media (min-width: 768px) {
              a:nth-child(2) {
                display: block;
              }
            }
          `
        )}
      >
        <Link className="flex items-center" to={'/all-products'}>
          <ProductIcon />
        </Link>
        <Link className="flex items-center" to={'/all-products'}>
          <span className="md:block text-base md:text-lg md:ml-2 md:text-lg">Products</span>
        </Link>
      </div>
    </div>
  </div>
)
