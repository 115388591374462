import React from 'react'
import { cx, css } from 'linaria'
import { Icon } from '@iconify/react'
import { Link } from 'gatsby'

import bxEnvelope from '@iconify/icons-bx/bx-envelope'

import svgAppStore from './svgAppStore.svg'
import svgGithub from './svgGithub.svg'
import { dfGithub, superbitsEmail, dwAppStore } from '@components/contants'
import BreadCrumb from '../breadcrumb'

const links = [
  {
    name: 'Home',
    href: '/',
  },
  {
    name: 'Products',
    href: '/all-products',
  },
  {
    name: 'Privacy Policy',
    href: 'http://github.com/dwarvesf',
  },
]

export const Footer = ({ path }: { path: string }) => {
  return (
    <footer
      className={cx(
        'bg-black',
        css`
          height: 300px;
          padding: 25px 0;
        `
      )}
    >
      <div
        className={cx(
          'mx-auto mb-6 h-full flex flex-col px-4',
          css`
            max-width: 72.5rem;
          `
        )}
      >
        <div
          className={cx(
            'flex flex-wrap justify-between w-full items-center pb-5 mb-6',
            css`
              border-bottom: 1px solid #525252;
            `
          )}
        >
          <BreadCrumb path={path} className="w-full sm:w-auto mb-5 sm:mb-0" />
          <div className="flex flex-row items-center">
            <span className="text-secondary-grey mr-4">Follow us on</span>

            <a href={dwAppStore} target="blank" rel="noopener noreferer">
              <img className="mr-4" alt="app-store-icon" src={svgAppStore} />
            </a>
            <a target="blank" href={dfGithub}>
              <img src={svgGithub} alt="github" />
            </a>
          </div>
        </div>
        <ul className="flex flex-row w-full">
          {links.map(({ name, href }) => (
            <li className="text-secondary-white cursor-pointer mr-3" key={name}>
              {name !== 'Privacy Policy' ? (
                <Link to={href}>{name}</Link>
              ) : (
                <a href={href} target="blank" rel="noopener noreferer">
                  {name}
                </a>
              )}
            </li>
          ))}
        </ul>
        <div className="flex w-full mt-auto flex-wrap justify-between items-center">
          <div className="text-secondary-grey mb-3 sm:mb-0">
            © {new Date().getFullYear()} Superbits Development Software
          </div>
          <div className="text-secondary-white flex items-center">
            <Icon icon={bxEnvelope} />
            <span className="ml-1">
              <a href={`mailto:${superbitsEmail}`}>hello@superbits.co</a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
