import { useStaticQuery, graphql } from 'gatsby'

const useLogoInDetail = (imageRegex: string) => {
  const data = useStaticQuery(graphql`
    query {
      allDesktopLogos: allFile(filter: { absolutePath: { regex: "/product/logo/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fixed(width: 146, height: 146) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const desktopLogo = data.allDesktopLogos.edges.find(({ node }) => {
    return node.relativePath.includes(imageRegex)
  })
  const sources = [desktopLogo.node.childImageSharp.fixed]
  return [sources]
}

export { useLogoInDetail }
