import React from 'react'
import { cx } from 'linaria'

import { ProductItemType } from '@/types'

export const DownloadNow = ({
  name,
  appStoreUrl,
  svgBox,
  boxImage,
  className,
  githubUrl,
  theme = 'light',
}: ProductItemType & { svgBox?: React.ReactNode; boxImage?: string; className?: string; theme?: string }) => (
  <section className={cx(className, 'py-10 md:py-15')}>
    <div className="container px-4">
      <div className="flex flex-wrap -mx-4 items-center justify-center">
        <div className={cx('px-3 sm:w-auto text-center sm:text-left')}>
          {svgBox}
          {boxImage && (
            <img className="max-w-full max-h-full" width="145" src={boxImage} alt={`Download ${name} box`} />
          )}
        </div>
        <div className="px-3 w-full sm:w-auto text-center sm:text-left">
          <h3 className={cx('font-500 font-header  text-2xl mb-3', theme === 'light' && 'text-secondary-black')}>
            Haven’t tried {name} yet?
          </h3>
          <a
            className="btn btn-primary text-base"
            title={`Download ${name}`}
            href={appStoreUrl || githubUrl}
            target="blank"
            rel="noopener noreferer"
          >
            Download now
          </a>
        </div>
      </div>
    </div>
  </section>
)
