import { css } from 'linaria'
import pngProductHiddenBg from '@/images/hidden/bg-hidden.png'
import { ProductItemType } from '@/types'

const dfLink = 'https://dwarves.foundation/'
const dfGithub = 'https://github.com/dwarvesf'
const superbitsEmail = 'hello@superbits.co'
const hiddenYCombinator = 'https://news.ycombinator.com/item?id=21794858'
const dwAppStore = 'https://apps.apple.com/us/developer/dwarves-foundation/id1452452150'
const hiddenVideo = 'https://share.getcloudapp.com/WnuG5kw6?embed=true'
const microSniffVideoDemo = 'https://share.getcloudapp.com/12uymr18?embed=true'
const allReviewsMicroSniffLink = 'https://apps.apple.com/vn/app/micro-sniff/id1504024265?mt=12'

export const HiddenBarApp: ProductItemType = {
  appId: '1452453066',
  name: 'Hidden Bar',
  subtitle: 'Hide menu bar icons on your Mac',
  longDesc: `With Hidden, you don’t have to look at those boring icons on your menu bar anymore. This ultra-light menu bar utility will help you to perfect your menu bar by completely hiding icons. Ever feel that the...`,
  shortDesc: 'Hidden is an ultra-light application that allows Mac users to hide icons on the menu bar.',
  bgClass: css`
    background-image: url(${pngProductHiddenBg});
    background-color: #000000d1;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  introBGClass: css`
    background: #eff1f2;
  `,
  appStoreUrl: 'https://apps.apple.com/us/app/hidden-bar/id1452453066',
  detailPath: '/hidden',
  imageRegex: 'hidden',
  githubUrl: 'https://github.com/dwarvesf/hidden',
  allReviewUrl: 'https://apps.apple.com/vn/app/hidden-bar/id1452453066?mt=12#see-all/reviews',
  yCombinatorUrl: 'https://news.ycombinator.com/item?id=21794858',
  reviews: [
    {
      createdBy: 'Paulaan',
      createdAt: '01/04/2020',
      title: 'Nice tool',
      content: 'Good work',
      isMore: false,
    },
    {
      createdBy: 'ledienphuc',
      createdAt: '13/12/19',
      title: 'Good app',
      content: 'Simple app for making status bar clean',
      isMore: false,
    },
    {
      createdBy: 'vinhnhq',
      createdAt: '13/12/2019',
      title: 'Simple but useful',
      content: `Please upgrade with a little guide line.`,
      isMore: false,
    },
    {
      createdBy: 'namnhce',
      createdAt: '12/12/2019',
      title: 'Simple but useful',
      content: `I have been used this app a long time. It's good for me.`,
      isMore: false,
    },
  ],
}

export const BlurredApp: ProductItemType = {
  appId: '1497527363',
  name: 'Blurred',
  subtitle: 'Focus on your work',
  longDesc:
    'During the workday, we have to open a few applications, but not all of them bring you the feel to productivity. They cause us to struggle with distraction and context switching, which leads to mental health',
  shortDesc: 'They cause us to struggle with distraction and context switching. which leads to mental health',
  bgClass: css`
    background: linear-gradient(180deg, #404e5c -7.87%, #273138 100%);
  `,
  introBGClass: css`
    background: linear-gradient(
      110.96deg,
      rgba(249, 239, 239, 0.89) -0.54%,
      rgba(227, 236, 241, 0.89) 50.25%,
      rgba(227, 225, 234, 0.89) 100%
    );
  `,
  appStoreUrl: 'https://apps.apple.com/us/app/blurred/id1497527363',
  detailPath: '/blurred',
  imageRegex: 'blurred',
  githubUrl: 'https://github.com/dwarvesf/Blurred',
  allReviewUrl: 'https://apps.apple.com/us/app/blurred/id1497527363?mt=12#see-all/reviews',
  reviews: [
    {
      createdBy: 'scavina',
      createdAt: '03/03/2020',
      title: 'like it',
      content: '👍',
      isMore: false,
    },
  ],
}

export const MicroSniffApp: ProductItemType = {
  appId: '1504024265',
  name: 'Micro Sniff',
  subtitle: 'Focus on your work',
  longDesc:
    'A meeting is coming through. Your camera is off, but all of a sudden you forget to unplug the earphones which, 10 minutes ago, was still jamming on that youtube video you...',
  shortDesc: 'Remove the concern of sound privacy when you forget to unplug the earphones',
  bgClass: css`
    background: linear-gradient(180deg, #007bec 0%, #004dca 108.92%);
  `,
  introBGClass: css`
    background: linear-gradient(110.96deg, #ebf4fa -0.54%, #d7e7f5 50.25%, #e1e5f0 100%);
  `,
  appStoreUrl: 'https://apps.apple.com/us/app/micro-sniff/id1504024265',
  detailPath: '/micro-sniff',
  imageRegex: 'micro-sniff',
  githubUrl: 'https://github.com/dwarvesf/Micro-Sniff/releases/tag/v1.0.0',
  allReviewUrl: 'https://apps.apple.com/vn/app/micro-sniff/id1504024265?mt=12',
  reviews: [
    {
      createdBy: 'ledienphuc',
      createdAt: '01/04/2020',
      title: 'Great app',
      content: 'Great app for keep your privacy safe',
      isMore: false,
    },
  ],
}

export const SessionBuddyApp: ProductItemType = {
  appId: '1515213004',
  name: 'Session Buddy',
  subtitle: 'Get your tabs back in no time',
  longDesc: `Your opened tabs perform great until you hit a button that somehow crashes down the whole thing. And the most ironic part? “Restore Option” somehow doesn’t even help.`,
  shortDesc: 'Restore the accidental-closed tabs, especially when they were opened and closed at the same time',
  bgClass: css`
    background: linear-gradient(180deg, rgba(230, 108, 0, 0.8) 0%, rgba(242, 40, 0, 0.8) 100%);
  `,
  introBGClass: css`
    background: linear-gradient(
      111.08deg,
      rgba(255, 238, 217, 0.8) 0%,
      rgba(255, 216, 204, 0.8) 51.04%,
      rgba(255, 219, 204, 0.8) 100%
    );
  `,
  appStoreUrl: 'https://apps.apple.com/us/app/session-buddy/id1515213004',
  detailPath: '/session-buddy',
  imageRegex: 'session-buddy',
  githubUrl: 'https://github.com/dwarvesf/session-buddy',
  allReviewUrl: 'https://apps.apple.com/us/app/session-buddy/id1515213004?mt=12#see-all/reviews',
}

export const AutoDNDApp: ProductItemType = {
  name: 'Auto DND',
  subtitle: 'Keep the notification stays right where it should',
  longDesc: `Once we're start to use application, desktop notification becomes a part of our lives. By allowing the web app to display notification at the right screen corner, it's easier...`,
  shortDesc: `Auto DnD's do-not-disturb mode avoids the chance of notification to be shown on your desktop screen.`,
  bgClass: css`
    background: linear-gradient(180deg, #333ea7 0%, #172874 100%);
  `,
  introBGClass: css`
    background: linear-gradient(180deg, rgba(215, 212, 251, 0.2) 0%, rgba(197, 209, 255, 0.2) 100%);
    border: 1px solid #d8dcff;
  `,
  detailPath: '/auto-dnd',
  imageRegex: 'auto-dnd',
  githubUrl: 'https://github.com/dwarvesf/auto-dnd',
  hideOnHome: true,
}

export const VimMotionApp: ProductItemType = {
  name: 'Vim Motion',
  subtitle: 'Tips to reduce the use of mouses? Get rid of it.',
  longDesc: `There are many catches to abandon the mouse. To move quickly between lines. Switching from tabs to tabs. Navigate the right element in a second.`,
  shortDesc: `There are many catches to abandon the mouse. To move quickly between lines. Switching from tabs to tabs. Navigate the right element in a second.`,
  bgClass: css`
    background: linear-gradient(180deg, #333ea7 0%, #172874 100%);
  `,
  introBGClass: css`
    background: linear-gradient(231.41deg, rgba(65, 180, 245, 0.15) 38.24%, rgba(10, 72, 232, 0.15) 99.05%);
    border: 1px solid #b5d1ff;
    box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.3), inset 0px -1px 1px rgba(0, 0, 0, 0.15),
      inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.25);
  `,
  detailPath: '/vim-motion',
  imageRegex: 'vim-motion',
  githubUrl: 'https://github.com/dwarvesf/VimMotionApp',
  hideOnHome: true,
}

const productList: ProductItemType[] = [
  HiddenBarApp,
  BlurredApp,
  MicroSniffApp,
  SessionBuddyApp,
  AutoDNDApp,
  VimMotionApp,
]

export {
  dfLink,
  dfGithub,
  superbitsEmail,
  hiddenYCombinator,
  dwAppStore,
  hiddenVideo,
  microSniffVideoDemo,
  allReviewsMicroSniffLink,
  productList,
}
