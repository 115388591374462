import React from 'react'
import { ProductItemType } from '@/types'

import Seo from '@components/seo'

export interface SeoDetailPageProps {
  video?: string
  images?: string[]
}

const SeoDetailPage = ({
  name,
  subtitle,
  longDesc,
  detailPath,
  appStoreUrl,
  appId,
  video,
  images,
}: ProductItemType & SeoDetailPageProps) => {
  return (
    <Seo
      title={`${name} - ${subtitle}`}
      description={longDesc}
      canonicalPath={detailPath}
      appId={appId}
      appStoreUrl={appStoreUrl}
      appName={name}
      video={video}
      images={images}
    />
  )
}

export default SeoDetailPage
