import React from 'react'
import { cx, css } from 'linaria'
import Img from 'gatsby-image'

import { useLogoInDetail } from '@/hooks/useLogo'
import { ProductItemType } from '@/types'
import { ReactComponent as ButtonDowloadOnTheAppStore } from '@components/svg/download-app-store.svg'

export const Jumbotron = ({ imageRegex, name, appStoreUrl, subtitle, bgClass }: ProductItemType) => {
  const [sources] = useLogoInDetail(imageRegex)
  return (
    <div
      className={cx(
        'flex flex-col text-secondary-light-grey items-center justify-center relative',
        bgClass,
        css`
          background-size: cover;
          margin-top: calc(-1 * var(--top-menu-mobile-height));
          padding-top: var(--top-menu-mobile-height);
          padding-bottom: var(--top-menu-mobile-height);

          @media (min-width: 768px) {
            margin-top: calc(-1 * var(--top-menu-height));
            padding-top: var(--top-menu-height);
            padding-bottom: var(--top-menu-height);
          }
        `
      )}
    >
      <div
        className={cx(
          'flex flex-col items-center px-4',
          css`
            margin-top: 72px;
          `
        )}
      >
        <Img fixed={sources} className="absolute z-10 mb-4" loading="eager" />
        <h1
          className={cx(
            'font-700 font-header uppercase',
            css`
              line-height: 1.3;
              margin-bottom: 0.3125rem;
              font-size: 40px;
            `
          )}
        >
          {name}
        </h1>
        <div
          className={cx(
            'font-500 text-center',
            css`
              font-size: 21px;
              margin-bottom: 1.5rem;
              @media (min-width: 768px) {
                font-size: 1.325rem;
                line-height: 1.8125rem;
              }
            `
          )}
        >
          {subtitle}
        </div>
        {appStoreUrl && (
          <a href={appStoreUrl} target="blank" rel="noopener noreferer">
            <ButtonDowloadOnTheAppStore />
          </a>
        )}
      </div>
    </div>
  )
}
